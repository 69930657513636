<template>
  <div class="product-list">
    <!-- 标题与保存按钮 -->
    <el-row>
      <el-col :span="12"><Title title="版本列表"></Title></el-col>
      <el-col :span="12">
        <div style="width:100%;height:60px;padding-right:15px;box-sizing:border-box" class="flex_rec">
          <el-button type="primary" @click="addAdmin">添加</el-button>
        </div>
      </el-col>
    </el-row>
    <!-- 产品列表 -->
    <el-card class="box-card">
      <el-row :gutter="20" type="flex" align="middle" justify="start" class="mt15">
        <el-col :span="1.5">
         平台
        </el-col>
        <el-col :span="3">
          <el-select v-model="search_op.platform" placeholder="选择平台" @change="getList">
            <el-option
              v-for="item in platforms"
              :key="item.value"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-table
        :data="list"

        style="width: 100%">
        <el-table-column
          prop="platform"
          width="100"
          label="平台">
        </el-table-column>

        <el-table-column
          prop="version"
          width="100"
          label="版本">
          <template slot-scope="scope">
            {{scope.row.version}}
          </template>
        </el-table-column>

        <el-table-column
          prop="status"
          label="状态"
          width="80"
          >
          <template slot-scope="scope">
            <template v-if="scope.row.status==1">
              <el-tag type="info" style="margin-right:5px">启用</el-tag>
            </template>
            <template v-if="scope.row.status==0">
              <el-tag type="danger" style="margin-right:5px">关闭</el-tag>
            </template>
          </template>
        </el-table-column>

        <el-table-column
          prop="is_browser_download"
          label="浏览器下载"
          width="80"
          >
          <template slot-scope="scope">
            <template v-if="scope.row.is_browser_download==1">
              <el-tag type="info" style="margin-right:5px">启用</el-tag>
            </template>
            <template v-if="scope.row.is_browser_download==0">
              <el-tag type="danger" style="margin-right:5px">关闭</el-tag>
            </template>
          </template>
        </el-table-column>

        <el-table-column
          prop="force"
          label="强制更新"
          width="80"
          >
          <template slot-scope="scope">
            <template v-if="scope.row.force==1">
              <el-tag type="info" style="margin-right:5px">强制</el-tag>
            </template>
            <template v-if="scope.row.force==0">
              <el-tag type="danger" style="margin-right:5px">非强制</el-tag>
            </template>
          </template>
        </el-table-column>

        <el-table-column
          prop="is_current"
          label="lasted"
          width="80"
          >
          <template slot-scope="scope">
            <template v-if="scope.row.is_current==1">
              <el-tag style="margin-right:5px">启用</el-tag>
            </template>
            <template v-if="scope.row.is_current==0">
              <el-tag type="danger" style="margin-right:5px">关闭</el-tag>
            </template>
          </template>
        </el-table-column>


        <el-table-column
          prop="create_time_str"
          width="200"
          label="创建时间">
        </el-table-column>

        <el-table-column
          prop="download_url"
          label="下载地址">
        </el-table-column>

        <!-- <el-table-column
          prop="comments"
          label="备注">
        </el-table-column> -->

        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <!-- <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete(scope.$index, scope.row)">删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page_num"
      :page-sizes="[5,10,15,20]"
      :page-size="page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
import Title from "./components/Title"
import apis from "@/api/index"
// import csv from "@/lib/arrObjToCsv.js"
// import util from "@/lib/util.js"
// import fileThum from "@/components/FileThum.vue"
export default {
    components:{
      Title,
      // fileThum
    },
    name:'admin',
    data(){
      return {
        status_arr:[
          {name:'启用/激活',value:1},
          {name:'禁用/未激活',value:0},
        ],
        platforms:[
          {name:'win32', value:'win32'},
          {name:'darwin', value:'darwin'},
          {name:'Android', value:'android'},
          {name:'Ios', value:'ios'},
        ],
        list:[],
        page_num:1,
        page_size:5,
        total:0,
        search_op:{},
      }
    },
    methods:{
      getList(toS=true){
        if(toS){
          this.page_num = 1;
        }
        let op = {
          page_num:this.page_num,
          page_size:this.page_size
        }
        Object.assign(op,this.search_op)
        apis.appversion.read(op).then(res=>{
          console.log('res',res)
          this.list = (res.data.data.list || []).map((v)=>{
            return v;
          })
          this.total = res.data.data.count
          // let csvO = new csv([
          //   "name","tags","stock_sum"
          // ],this.list)
          // console.log('parse',csvO.parse())
          // console.log('parse',csvO.exportCsv())
        }).catch(err=>{
          console.log('err',err)
        })
      },
      handleEdit(index, row) {
        this.$router.push({name:'updateAppversion',params:{obj:row}})
      },
      handleDelete(index, row) {
        console.log(index, row);
      },
      handleSizeChange(val) {
        this.page_size = val
        this.getList(false)
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        this.page_num = val
        this.getList(false)
        console.log(`当前页: ${val}`);
      },

      // tableExpandChange(row,expand){

      // },
      addAdmin(){
        this.$router.push({name:'addAppversion'})
      }
    },
    mounted(){
      this.getList()

    }
}
</script>

<style lang="scss" scoped>
.product-list{
  padding:15px;
}

</style>